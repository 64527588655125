// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import polarisStyles from '@shopify/polaris/build/esm/styles.css';
import popupListStyles from "./routes/dashboard/style.css";
import panelSelectSpinWheelThemeStyles from "./routes/select-popup-theme/style.css";
import panelQuickSetupSpinWheel from "./routes/quick-setup-spin-wheel.$theme_id/style.css";
import desktopSpinWheelDemoStyles from "./routes/quick-setup-spin-wheel.$theme_id/components/desktop_demo/desktop_demo.css";
import mobileSpinWheelDemoStyles from "./routes/quick-setup-spin-wheel.$theme_id/components/mobile_demo/mobile_demo.css";
import tailwindStyle from "./tailwind.css";
export default function App() {
  return <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css" />

        <meta name="shopify-api-key" content="e9b73bea3013423f3bf553bad751ded8" />
        <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js"></script>

        {/* google tag manager */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QLDPQ2DBJB"></script>
        <script dangerouslySetInnerHTML={{
        __html: `
              // Your custom inline JavaScript code here
              console.log('Custom script loaded ga4');
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-QLDPQ2DBJB', {
                'cookie_flags': 'SameSite=None; Secure'
              });
            `
      }}>
        </script>
        {/* end of google tag manager */}

        {/* microsoft clarity */}
        <script dangerouslySetInnerHTML={{
        __html: `
              // Your custom inline JavaScript code here
              console.log('Custom script loaded clarity');
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "nlktbj08g3");
            `
      }}>
        </script>
        {/* end of microsoft clarity */}

        {/* <link rel="stylesheet" href={bootstrap}/> */}
        <link rel="stylesheet" href={polarisStyles}></link>
        <link rel="stylesheet" href={popupListStyles} />
        <link rel="stylesheet" href={panelSelectSpinWheelThemeStyles} />
        <link rel="stylesheet" href={panelQuickSetupSpinWheel} />
        <link rel="stylesheet" href={desktopSpinWheelDemoStyles} />
        <link rel="stylesheet" href={mobileSpinWheelDemoStyles} />
        <link rel="stylesheet" href={tailwindStyle} />
        <script src="https://code.highcharts.com/highcharts.js"></script>
        <Meta />
        <Links />
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-L4XPYDK44X"></script>
        <script dangerouslySetInnerHTML={{
        __html: `
              // Your custom inline JavaScript code here
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-L4XPYDK44X',{
                'cookie_flags': 'SameSite=None; Secure'
              });
            `
      }}>
        </script>
        {/* end of Google tag */}
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <LiveReload />
        <Scripts />
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145086901.js"></script>
      </body>
    </html>;
}
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;